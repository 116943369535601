<!-- 批号修改 -->
<template>
  <div class="pagePadding">
    <Form inline class="formMarginBtm20">
      <!-- 产品名称 -->
      <FormItem>
        <span>产品名称：</span>
        <Select placeholder="请选择" v-model="queryFrom.product_id" @on-change="productChange" clearable filterable class="iviewIptWidth250">
          <Option v-for="(item, index) in productNameList" :value="item.product_id" :key="index + item.name">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <!-- 规格型号/SKU -->
      <FormItem class="marginLeft40">
        <span>规格型号/SKU：</span>
        <Select placeholder="请选择" v-model="queryFrom.specification_id_str" clearable filterable multiple :max-tag-count="1" class="iviewIptWidth250">
          <Option v-for="(item, index) in skuList" :key="index" :value="item.specification_id" :label="item.model_name + '-' + item.item_number">
            <span>{{ item.model_name }}</span>
            <span style="float: right; color: #ccc; margin-right: 20px;">{{ item.item_number }}</span>
          </Option>
        </Select>
      </FormItem>
      <!-- 修改人 -->
      <FormItem class="marginLeft40">
        <span>修改人：</span>
        <Select placeholder="请选择" v-model="queryFrom.modifier_id" clearable filterable class="iviewIptWidth250">
          <Option v-for="(item, index) in userList" :value="item.id" :key="index">{{ item.user_name }}</Option>
        </Select>
      </FormItem>
      <!-- 修改时间 -->
      <FormItem class="marginLeft40">
        <span>修改时间：</span>
        <DatePicker :editable="false" type="daterange" placeholder="请选择" format="yyyy-MM-dd" class="iviewIptWidth250" @on-change="changeTime"></DatePicker>
      </FormItem>
      <!-- 审核状态 -->
      <FormItem>
        <span>审核状态：</span>
        <Select placeholder="请选择" v-model="queryFrom.status" clearable filterable class="iviewIptWidth250">
          <Option v-for="(item, index) in statusList" :value="item.value" :key="index">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <!-- 按钮 -->
      <FormItem class="po-create-number marginRight0" :label-width="10">
        <span class="pageBtn finger btnSure" @click="query">查询</span>
        <span class="longTextBtn finger btnReset marginLeft20" @click="addChangeBatch">新增修改</span>
      </FormItem>
    </Form>
    <!-- <Table :columns="listColumns" :data="listData" totalText="条记录" @change-page="changePage" highlight-row :row-class-name="rowClassName"></Table> -->
    <Table :productList="listColumns" :productData="listData" :total="total" :pages="queryFrom" @change-page="changePage" :isLoad="isLoad" :loading="isLoad" totalText="条记录" :rowClass="rowClassName">
      <!-- <div slot="summary" class="summary" v-if="listData.length > 0">
        <div>
          <span style="margin-right: 20px;"
            >总数量：<span class="color389">{{ totalNum }}</span></span
          >
          <span>合计金额：</span>
          <span class="color389">¥{{ totalMoney }}</span>
        </div>
      </div> -->
    </Table>
    <!-- <div class="pages" v-if="listData !== null && listData.length > 0 && total">
      <Page v-if="queryFrom && total <= 60" :total="total" :current="queryFrom.page" @on-change="changePage" class="fl" />
      <Page v-if="queryFrom && total > 60" :total="total" :current="queryFrom.page" @on-change="changePage" show-elevator class="fl" />
      <div class="fr totalFont" v-if="total">共计{{ total }}条记录</div>
    </div> -->
    <!--    审核-->
    <Modal v-model="reviewStatus" label-position="left" width="400" :footer-hide="true" class-name="vertical-center-modal">
      <div class="reviewDiv">
        <p>批号修改审核通过？</p>
        <div>
          <span class="pageBtn finger btnSure" @click="approved(1)">通过</span>
          <span class="pageBtn finger btnCancle" @click="approved(0)">不通过</span>
        </div>
      </div>
    </Modal>
    <!-- 反冲确认-弹窗 -->
    <Modal v-model="isConfirm[0]" label-position="left" width="400" :footer-hide="true" :mask-closable="false" :closable="false" class-name="vertical-center-modal">
      <div class="topModal clearfix">
        <img src="@/assets/images/tipsPostImg.png" class="tipsImg fl" />
        <div class="flDiv fl">
          <p>反冲</p>
          <p>是否确认反冲</p>
        </div>
      </div>
      <div class="foot">
        <span class="pageBtn finger btnCancle" @click="isConfirm = [false, undefined]">取消</span>
        <span class="pageBtn finger marginLeft20 btnSure" @click="recoil(isConfirm[1])">确定</span>
      </div>
    </Modal>
  </div>
</template>

<script>
import Table from '@/components/table'

export default {
  name: 'changeBatch',
  components: {
    Table,
  },
  data() {
    return {
      productNameList: [],
      skuList: [],
      reviewStatus: false,
      total: 1,
      listData: [],
      // 反冲确认弹窗 + 入库单id
      isConfirm: [false, undefined],
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 65,
        },
        {
          title: '修改单号',
          key: 'order_number',
          align: 'center',
        },
        {
          title: '修改人',
          key: 'modifier',
          align: 'center',
        },
        {
          title: '产品名称',
          key: 'product_name_list',
          align: 'center',
        },
        {
          title: '修改时间',
          key: 'modifyTime',
          align: 'center',
        },
        {
          title: '审核人',
          key: 'user_name',
          align: 'center',
        },
        {
          title: '审核状态',
          key: 'status_str',
          align: 'center',
        },
        {
          title: '操作',
          align: 'center',
          width: 189,
          render: (h, param) => {
            // 审核 or 详情
            if (param.row.status == '1' || param.row.status == '0') {
              return h('div', [
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',

                      cursor: 'pointer',
                    },
                    on: {
                      click: () => {
                        this.details(param.row)
                      },
                    },
                  },
                  '详情'
                ),
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',
                      marginLeft: '20px',
                      cursor: 'pointer',
                      display: param.row.status == '1' ? 'inline-block' : 'none',
                    },
                    on: {
                      click: () => {
                        this.isConfirm = [true, param.row.id]
                      },
                    },
                  },
                  '反冲'
                ),
              ])
            } else {
              return h('div', [
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',
                      color: param.row.status == '2' ? 'red' : '#389AFC',
                      cursor: 'pointer',
                    },
                    on: {
                      click: () => {
                        this.review(param.row)
                      },
                    },
                  },
                  '审核'
                ),
              ])
            }
          },
        },
      ],
      queryFrom: {
        page: 1,
        rows: 10,
        modifier_id: '',
        modify_time_start: '',
        modify_time_end: '',
        status: '',
      },
      userList: [],
      statusList: [
        {
          name: '未审核',
          value: '-1',
        },
        {
          name: '审核拒绝',
          value: '0',
        },
        {
          name: '审核通过',
          value: '1',
        },
      ],
      reviewId: '',
      isLoad:true
    }
  },
  activated() {
    if (sessionStorage.getItem('updataCache') == 0) {
      this.query()
      sessionStorage.setItem('updataCache', '1')
    }
  },
  mounted() {
    this.query()
    this.specialUser()
    this.getProductNameList()
  },
  methods: {
    // 反冲成功的行标红
    rowClassName(row, index) {
      if (row.status == 2) {
        return 'redRow'
      }
      return ''
    },
    // 反冲
    recoil(id) {
      this.$http.get(this.$api.getbatchmodifyRecoil, { id: id }, true).then(res => {
        if (res.status) {
          this.$Message.success('反冲成功')
          this.queryList()
        }
      })
      this.isConfirm = [false, undefined]
    },
    // 产品编改变
    productChange(e) {
      this.skuList = []
      this.queryFrom.specification_id = ''
      // if (!e) return
      // 规格/SKU下拉
      if (!e) {
        this.queryFrom.specification_id_str = ''
        this.$http.get(this.$api.productManageSearchProductCode, true).then(res => {
          this.skuList = res.data
        })
      } else {
        this.$http.get(this.$api.productManageSearchProductCode, { product_id: String(this.queryFrom.product_id) }).then(res => {
          this.skuList = res.data
        })
      }
    },
    // 获取产品名称下拉列表,sku初始化表
    getProductNameList() {
      this.$http.get(this.$api.productManageSearchProduct, true).then(res => {
        this.productNameList = res.data
      })
      this.$http.get(this.$api.productManageSearchProductCode, true).then(res => {
        this.skuList = res.data
      })
    },
    approved(num) {
      let data = {
        id: this.reviewId,
        result: num,
      }
      this.$http.put(this.$api.batchModify, data, true).then(res => {
        this.reviewStatus = false
        this.query()
      })
    },
    // 点击审核
    review(row) {
      this.reviewId = row.id
      this.reviewStatus = true
    },
    // 查询修改人
    specialUser() {
      this.$http.get(this.$api.publicSpecialUser, { user_type: '2' }, true).then(res => {
        this.userList = res.data
      })
    },
    // 时间改变
    changeTime(e) {
      if (e[0] == '' && e[1] == '') {
        this.queryFrom.modify_time_start = null
        this.queryFrom.modify_time_end = null
      } else {
        this.queryFrom.modify_time_start = e[0]
        this.queryFrom.modify_time_end = e[1]
      }
    },
    query() {
      this.queryFrom.page = 1
      this.queryList()
    },
    // 查询列表数据
    queryList() {
      this.isLoad = true
      this.$http.get(this.$api.batchModify, this.queryFrom, true).then(res => {
        this.isLoad = false
        for (let i = 0; i < res.data.length; i++) {
          res.data[i].modifyTime = res.data[i].modify_time ? this.$moment(res.data[i].modify_time * 1000).format('YYYY-MM-DD') : ''
        }
        this.listData = res.data
        this.total = res.total
      })
    },
    // 新增修改
    addChangeBatch() {
      this.$router.push({
        path: '/addChangeBatch',
      })
    },
    // 翻页
    changePage(e) {
      this.queryFrom.page = e
      this.queryList()
    },
    // 详情
    details(row) {
      this.$router.push({
        path: '/addChangeBatch',
        query: {
          id: row.id,
        },
      })
    },
  },
}
</script>

<style scoped lang="less">
// 反冲确认弹窗
.foot {
  text-align: right;
}
.topModal {
  padding-bottom: 56px;
  margin-top: 14px;
  .tipsImg {
    width: 36px;
    height: 36px;
    margin: 0 13px 0 9px;
  }
  .flDiv {
    p:nth-child(1) {
      display: block;
      height: 24px;
      font-size: 18px;
      font-weight: 600;
      color: rgba(51, 51, 51, 1);
      line-height: 24px;
      margin-bottom: 7px;
    }
    p:nth-child(2) {
      height: 20px;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
    }
  }
}
</style>
